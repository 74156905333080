import { Section } from '../../models/section';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const sectionAdapter: EntityAdapter<Section> = createEntityAdapter<Section>({
  selectId: model => model.id,
  // sortComparer: (a: Section, b: Section): number => b.id.toString().localeCompare(a.id.toString())
});

export interface State extends EntityState<Section> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = sectionAdapter.getInitialState({
  isLoading: false,
  error: null
});
