<section id="about" class="about-area ptb-100 bg-image" *ngIf="!(isLoading$ |async)">
  <div class="container" *ngIf="(page$ | async) !== null">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-12">
        <div class="row about-image">

          <div class="col-12">
            <div class="image">
              <img [src]="(page$ | async).image.image_url" [alt]="(page$ |  async).title">
            </div>
          </div>

        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="about-content">
          <span>{{ (page$ | async)?.title }}</span>
          <div [innerHTML]="(page$ | async)?.description"></div>
        </div>
      </div>
    </div>
  </div>
</section>
