import { Page } from '../../models/page';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_PAGE_REQUEST = '[Page] Load Request',
  LOAD_PAGE_FAILURE = '[Page] Load Failure',
  LOAD_PAGE_SUCCESS = '[Page] Load Success'
}

export class LoadPageRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PAGE_REQUEST;
}

export class LoadPageFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PAGE_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadPageSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PAGE_SUCCESS;

  constructor(public payload: { items: Page[] }) {}
}

export type Actions = LoadPageRequestAction | LoadPageFailureAction | LoadPageSuccessAction;
