import { Observable } from 'rxjs';
import { ProductCategory } from './../../../../models/productCategory';
import { Component, OnInit } from '@angular/core';
import { TabsetConfig } from 'ngx-bootstrap/tabs';
import { Store } from '@ngrx/store';

import { ProductCategoryStoreActions, ProductCategoryStoreSelectors, RootStoreState } from 'src/app/root-store';

export function getTabsetConfig(): TabsetConfig {
  return Object.assign(new TabsetConfig(), { type: 'pills', isKeysAllowed: false });
}

@Component({
  selector: 'app-solutions',
  templateUrl: './solutions.component.html',
  styleUrls: ['./solutions.component.scss'],
  providers: [{ provide: TabsetConfig, useFactory: getTabsetConfig }]
})
export class SolutionsComponent implements OnInit {

  productCategories$: Observable<ProductCategory[]> = this.store$.select(ProductCategoryStoreSelectors.selectAllProductCategories);
  error$: Observable<string> = this.store$.select(ProductCategoryStoreSelectors.selectProductCategoryError);
  isLoading$: Observable<boolean> = this.store$.select(ProductCategoryStoreSelectors.selectProductCategoryIsLoading);
  currentTab = 0;

  constructor(
    private store$: Store<RootStoreState.State>
  ) { }

  ngOnInit(): void {
    this.getProductCategories();
  }

  getProductCategories() {
    this.store$.dispatch(new ProductCategoryStoreActions.LoadProductCategoryRequestAction());
  }
}
