<section id="blog" class="blog-area ptb-100" *ngIf="!(isLoading$ | async)">
  <div class="container">
    <div class="section-title">
      <span>Blog</span>
      <h2>Son <b>Yazılar</b> ve <b>Haberler</b></h2>
      <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p> -->
    </div>

    <owl-carousel [options]="owlOptions" [carouselClasses]="carouselClasses">
      <div class="single-blog-post" *ngFor="let post of posts$ | async as posts; let i = index">
        <div class="blog-image">
          <a [routerLink]="['/blog/' + post.slug]" [title]="post.title">
            <img [src]="post.image.image_url" [alt]="post.title" />
          </a>
        </div>
        <div class="blog-post-content">
          <span class="date">{{ post.created_at_tr }}</span>

          <h3>
            <a [routerLink]="['/blog/' + post.slug]" [title]="post.title">{{
              post.title
              }}</a>
          </h3>

          <p>{{ post.description }}</p>

          <a [routerLink]="['/blog/' + post.slug]" class="read-more-btn">Detaylar <i
              class="icofont-double-right"></i></a>
        </div>
      </div>
    </owl-carousel>

    <div class="row mt-5">
      <div class="col-12 text-center">
        <a routerLink="/blog" class="btn btn-primary" title="Tüm Yazılar">Tüm Yazılar</a>
      </div>
    </div>
  </div>
</section>
