import { ContactInfoSetting } from './../../../../models/contactInfoSetting';
import { SettingService } from './../../../../services/setting.service';
import { AlertifyService } from './../../../../services/alertify.service';
import { ContactService } from './../../../../services/contact.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  errors: string[];
  sending = false;

  contactInfoSetting: ContactInfoSetting;
  loading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private contactService: ContactService,
    private alertifyService: AlertifyService,
    private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.getContactInfoSetting();
    this.createContactMailForm();
  }

  onSubmit() {
    this.sending = true;

    this.contactService.sendMail(this.contactForm.value)
      .pipe(
        catchError(error => {
          return throwError(error);
        })
      )
      .subscribe((response) => {
        if (response.success === true) {
          this.alertifyService.success(response.message);
          this.contactForm.reset();
        } else {
          this.alertifyService.success(response);
        }
        this.sending = false;
      }, error => {
        this.sending = false;
        this.alertifyService.error(error.error.message);
      });
  }

  getContactInfoSetting() {
    this.loading = true;
    this.settingService.getContactInfoSetting().subscribe((response: ContactInfoSetting) => {
      this.contactInfoSetting = response;
      this.loading = false;
    });
  }

  createContactMailForm() {
    this.contactForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      email: [
        null, [
          Validators.required,
          Validators.pattern('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$')
        ],
      ],
      subject: [null, [Validators.required]],
      phone: [null, []],
      message: [null, [Validators.required]]
    });
  }
}
