import { Actions, ActionTypes } from './actions';
import { productCategoryAdapter, initialState, State } from './state';

export function productCategoryReducer(state = initialState, action: Actions): State {
  switch(action.type) {

    case ActionTypes.LOAD_PRODUCT_CATEGORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ActionTypes.LOAD_PRODUCT_CATEGORY_SUCCESS: {
      return productCategoryAdapter.addMany(action.payload.items, {
        ...state,
        isLoading: false,
        error: null
      });
    }

    case ActionTypes.LOAD_PRODUCT_CATEGORY_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    default: {
      return state;
    }
  }
}
