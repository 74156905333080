<section id="contact" class="contact-area ptb-100 bg-image">
  <div class="container">
    <div class="section-title">
      <span>Temasta Olmak İçin</span>
      <h2>Bize Ulaşın</h2>
      <p>Aşağıda verilen iletişim bilgilerini kullanarak ya da formu doldurarak bize ulaşabilirsiniz.</p>
    </div>
    <div class="row">
      <div class="col-lg-5 col-md-12">
        <div class="contact-info">
          <div class="d-table">
            <div class="d-table-cell" *ngIf="!loading">
              <ul>
                <li *ngIf="contactInfoSetting.location !== null"><i class="icofont-google-map"></i>
                  <span>Adres:</span>{{ contactInfoSetting.location }}</li>
                <li *ngIf="contactInfoSetting.email !== null"><i class="icofont-envelope"></i> <span>Email:</span><a
                    [href]="'mailto:' + contactInfoSetting.email">{{ contactInfoSetting.email }}</a></li>
                <li *ngIf="contactInfoSetting.phone !== null"><i class="icofont-phone"></i> <span>Telefon:</span><a
                    [href]="'tel:' + contactInfoSetting.phone">{{ contactInfoSetting.phone }}</a></li>
                <li *ngIf="contactInfoSetting.fax !== null"><i class="icofont-fax"></i> <span>Fax:</span><a
                    [href]="'tel:' + contactInfoSetting.fax">{{ contactInfoSetting.fax }}</a></li>
                <li *ngIf="contactInfoSetting.facebook !== null"><i class="icofont-facebook"></i>
                  <span>Facebook:</span><a [href]="contactInfoSetting.facebook" target="_blank">{{
                    contactInfoSetting.facebook }}</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-12">
        <div class="contact-form">
          <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">

            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="text" class="form-control"
                    [ngClass]="{'is-invalid': contactForm.get('name').errors && contactForm.get('name').touched}"
                    placeholder="Adınız" formControlName="name">
                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('name').touched && contactForm.get('name').hasError('required')">
                    Ad Soyad alanı gereklidir!
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group">
                  <input type="email" class="form-control" placeholder="Email Adresi" formControlName="email"
                    [ngClass]="{'is-invalid': contactForm.get('email').errors && contactForm.get('email').touched}">

                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('required')">
                    E-Posta adresi gereklidir!
                  </div>

                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('email').touched && contactForm.get('email').hasError('pattern')">
                    E-Posta adresi geçerli değil!
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Mesaj Konusu" formControlName="subject"
                    [ngClass]="{'is-invalid': contactForm.get('subject').errors && contactForm.get('subject').touched}">

                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('subject').touched && contactForm.get('subject').invalid">
                    Mesaj konusu gereklidir!
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <input type="text" class="form-control" placeholder="Telefon Numarası" formControlName="phone"
                    [ngClass]="{'is-invalid': contactForm.get('phone').errors && contactForm.get('phone').touched}">

                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('phone').touched && contactForm.get('phone').hasError('required')">
                    Telefon numarası gereklidir!
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <div class="form-group">
                  <textarea placeholder="Mesajınız" class="form-control" cols="30" rows="5" formControlName="message"
                    [ngClass]="{'is-invalid': contactForm.get('message').errors && contactForm.get('message').touched}"></textarea>

                  <div class="invalid-feedback"
                    *ngIf="contactForm.get('message').touched && contactForm.get('message').hasError('required')">
                    Mesaj alanı gereklidir!
                  </div>
                </div>
              </div>
              <div class="col-lg-12 col-md-12">
                <button [disabled]="contactForm.invalid || sending" type="submit" class="btn btn-primary"
                  [ngClass]="{'not-allowed' : !contactForm.valid || sending}">
                  <!-- <i class="fa fa-spinner fa-spin mr-2" aria-hidden="true" *ngIf="sending"></i> -->
                  {{ sending === true ? 'Gönderiliyor...' : 'Gönder' }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
