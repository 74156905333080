import { PostService } from './../../services/post.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as PostActions from './actions';

@Injectable()
export class PostStoreEffects {
  constructor(
    private postService: PostService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<PostActions.LoadPostRequestAction>(
      PostActions.ActionTypes.LOAD_POST_REQUEST
    ),

    mergeMap(() => this.postService.getAll().pipe(
        map(items => new PostActions.LoadPostSuccessAction({ items })),
        catchError((error) => observableOf(new PostActions.LoadPostFailureAction({ error })))
      ))
    )
  );
}
