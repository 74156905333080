import { Actions, ActionTypes } from './actions';
import { sectionAdapter, initialState, State } from './state';

export function sectionReducer(state = initialState, action: Actions): State {
  switch (action.type) {

    case ActionTypes.LOAD_SECTION_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ActionTypes.LOAD_SECTION_SUCCESS: {
      return sectionAdapter.addMany(action.payload.items, {
        ...state,
        isLoading: false,
        error: null
      });
    }

    case ActionTypes.LOAD_SECTION_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    default: {
      return state;
    }
  }
}
