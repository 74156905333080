import { createSelector, MemoizedSelector } from '@ngrx/store';

import { SliderStoreSelectors } from './slider-store';
import { ProductCategoryStoreSelectors } from './product-category-store';
import { PageStoreSelectors } from './page-store';
import { PostStoreSelectors } from './post-store';
import { ProductStoreSelectors } from './product-store';
import { SectionStoreSelectors } from './section-store';

export const selectError: MemoizedSelector<object, string> = createSelector(
  SliderStoreSelectors.selectSliderError,
  ProductCategoryStoreSelectors.selectProductCategoryError,
  PageStoreSelectors.selectPageError,
  PostStoreSelectors.selectPostError,
  ProductStoreSelectors.selectProductError,
  SectionStoreSelectors.selectSectionError,
  (
    sliderError: string,
    productCategoryError: string,
    pageError: string,
    postError: string,
    productError: string,
    sectionError: string,
  ) => {
    return sliderError || productCategoryError || pageError || postError || productError || sectionError;
  }

);

export const selectIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(
  SliderStoreSelectors.selectSliderIsLoading,
  ProductCategoryStoreSelectors.selectProductCategoryIsLoading,
  PageStoreSelectors.selectPageIsLoading,
  PostStoreSelectors.selectPostIsLoading,
  ProductStoreSelectors.selectProductIsLoading,
  SectionStoreSelectors.selectSectionIsLoading,
  (
    sliderIsLoading: boolean,
    productCategoryIsLoading: boolean,
    pageIsLoading: boolean,
    postIsLoading: boolean,
    productIsLoading: boolean,
    sectionIsLoading: boolean,
  ) => {
    return sliderIsLoading || productCategoryIsLoading || pageIsLoading || postIsLoading || productIsLoading || sectionIsLoading;
  }
);
