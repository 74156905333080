import { ProductService } from './../../services/product.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as ProductActions from './actions';

@Injectable()
export class ProductStoreEffects {
  constructor(
    private productService: ProductService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<ProductActions.LoadProductRequestAction>(
      ProductActions.ActionTypes.LOAD_PRODUCT_REQUEST
    ),

    mergeMap(() => this.productService.getAll().pipe(
        map(items => new ProductActions.LoadProductSuccessAction({ items })),
        catchError((error) => observableOf(new ProductActions.LoadProductFailureAction({ error })))
      ))
    )
  );
}
