import { SectionService } from '../../services/section.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as sectionActions from './actions';

@Injectable()
export class SectionStoreEffects {
  constructor(
    private sectionService: SectionService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<sectionActions.LoadSectionRequestAction>(
      sectionActions.ActionTypes.LOAD_SECTION_REQUEST
    ),

    mergeMap(() => this.sectionService.getAll().pipe(
        map(items => new sectionActions.LoadSectionSuccessAction({ items })),
        catchError((error) => observableOf(new sectionActions.LoadSectionFailureAction({ error })))
      ))
    )
  );

}
