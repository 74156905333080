import { Slider } from './../../../../models/slider';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState, SliderStoreActions, SliderStoreSelectors } from 'src/app/root-store';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnInit {

  sliders$: Observable<Slider[]> = this.store$.select(SliderStoreSelectors.selectAllSliders);
  error$: Observable<string> = this.store$.select(SliderStoreSelectors.selectSliderError);
  isLoading$: Observable<boolean> = this.store$.select(SliderStoreSelectors.selectSliderIsLoading);

  owlOptions = {
    items: 1,
    dots: false,
    navigation: true,
    nav: true,
    navText: [
      "<i class='icofont-rounded-left'></i>",
      "<i class='icofont-rounded-right'></i>"
    ],
  };

  constructor(
    private store$: Store<RootStoreState.State>
  ) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.store$.dispatch(new SliderStoreActions.LoadSliderRequestAction());
  }
}
