import { productCategoryAdapter, State } from './state';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { ProductCategory } from 'src/app/models/productCategory';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectProductCategoryState: MemoizedSelector<object, State> = createFeatureSelector<State>('productCategory');

export const selectAllProductCategories: (state: object) => ProductCategory[] = productCategoryAdapter.getSelectors(selectProductCategoryState).selectAll;

export const selectProductCategoryBySlug = (slug: string) =>
  createSelector(
    selectAllProductCategories,
    (allProductCategories: ProductCategory[]) => {
      if (allProductCategories) {
        return allProductCategories.find(p => p.slug === slug);
      } else {
        return null;
      }
    }
  );

export const selectProductCategoriesIfExistsProducts = () =>
  createSelector(
    selectAllProductCategories,
    (allProductCategories: ProductCategory[]) => {
      if (allProductCategories) {
        return allProductCategories.filter(p => p.product_count > 0);
      } else {
        return null;
      }
    }
  );

export const selectOtherProductCategories = (slug: string) => createSelector(
  selectAllProductCategories,
  (allProductCategories: ProductCategory[]) => {
    if (allProductCategories) {
      return allProductCategories.filter(p => p.slug !== slug);
    } else {
      return null;
    }
  }
);

export const selectProductCategoryError: MemoizedSelector<object, any> = createSelector(
  selectProductCategoryState,
  getError
);

export const selectProductCategoryIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectProductCategoryState,
  getIsLoading
);
