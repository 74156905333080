import { Observable } from 'rxjs';
import { Page } from './../../../../models/page';
import { Component, OnInit } from '@angular/core';

import { PageStoreActions, PageStoreSelectors, RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-productivity',
  templateUrl: './productivity.component.html',
  styleUrls: ['./productivity.component.scss']
})
export class ProductivityComponent implements OnInit {

  layout = 'productivity';
  page$: Observable<Page> = this.store$.select(PageStoreSelectors.selectPageByLayout(this.layout));
  error$: Observable<string> = this.store$.select(PageStoreSelectors.selectPageError);
  isLoading$: Observable<boolean> = this.store$.select(PageStoreSelectors.selectPageIsLoading);
  image_url: string = null;

  constructor(
    private store$: Store<RootStoreState.State>
  ) { }

  ngOnInit(): void {
    this.getPage();
  }

  getPage() {
    this.store$.dispatch(new PageStoreActions.LoadPageRequestAction());
    this.page$.subscribe((page) => {
      if (page !== undefined) {

        if (page.image !== null) {
          this.image_url = `url("${page.image.image_url}")`;
        }
      }
    });
  }
}
