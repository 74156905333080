import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { sliderReducer } from './reducer';
import { SliderStoreEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('slider', sliderReducer),
    EffectsModule.forFeature([
      SliderStoreEffects
    ])
  ]
})
export class SliderStoreModule { }
