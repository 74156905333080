import { ProductCategoryService } from './../../services/product-category.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as productCategoryActions from './actions';

@Injectable()
export class ProductCategoryStoreEffects {
  constructor(
    private productCategoryService: ProductCategoryService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<productCategoryActions.LoadProductCategoryRequestAction>(
      productCategoryActions.ActionTypes.LOAD_PRODUCT_CATEGORY_REQUEST
    ),

    mergeMap(() => this.productCategoryService.getAll().pipe(
        map(items => new productCategoryActions.LoadProductCategorySuccessAction({ items })),
        catchError((error) => observableOf(new productCategoryActions.LoadProductCategoryFailureAction({ error })))
      ))
    )
  );
}
