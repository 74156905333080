import { Observable } from 'rxjs';
import { Mail } from './../models/mail';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  constructor(
    private httpClient: HttpClient
  ) { }

  sendMail(mail: Mail): Observable<any> {
    return this.httpClient.post<any>('sendmail', mail);
  }
}
