import { Product } from '../../models/product';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_PRODUCT_REQUEST = '[Product] Load Request',
  LOAD_PRODUCT_FAILURE = '[Product] Load Failure',
  LOAD_PRODUCT_SUCCESS = '[Product] Load Success'
}

export class LoadProductRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_REQUEST;
}

export class LoadProductFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadProductSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_SUCCESS;

  constructor(public payload: { items: Product[] }) {}
}

export type Actions = LoadProductRequestAction | LoadProductFailureAction | LoadProductSuccessAction;
