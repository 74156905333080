import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { pageReducer } from './reducer';
import { PageStoreEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('page', pageReducer),
    EffectsModule.forFeature([
      PageStoreEffects
    ])
  ]
})
export class PageStoreModule { }
