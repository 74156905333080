import { ProductCategory } from '../../models/productCategory';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_PRODUCT_CATEGORY_REQUEST = '[ProductCategory] Load Request',
  LOAD_PRODUCT_CATEGORY_FAILURE = '[ProductCategory] Load Failure',
  LOAD_PRODUCT_CATEGORY_SUCCESS = '[ProductCategory] Load Success'
}

export class LoadProductCategoryRequestAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_CATEGORY_REQUEST;
}

export class LoadProductCategoryFailureAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_CATEGORY_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadProductCategorySuccessAction implements Action {
  readonly type = ActionTypes.LOAD_PRODUCT_CATEGORY_SUCCESS;

  constructor(public payload: { items: ProductCategory[] }) {}
}

export type Actions = LoadProductCategoryRequestAction | LoadProductCategoryFailureAction | LoadProductCategorySuccessAction;
