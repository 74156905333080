import { Section } from './../../models/section';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';


import { sectionAdapter, State } from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectSectionState: MemoizedSelector<object, State> = createFeatureSelector<State>('section');
export const selectAllSections: (state: object) => Section[] = sectionAdapter.getSelectors(selectSectionState).selectAll;

export const selectSectionByTemplate = (template: string) =>
  createSelector(
    selectAllSections,
    (allSections: Section[]) => {
      if (allSections) {
        return allSections.find(p => p.template === template);
      } else {
        return null;
      }
    }
  );

export const selectSectionError: MemoizedSelector<object, any> = createSelector(
  selectSectionState,
  getError
);


export const selectSectionIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSectionState,
  getIsLoading
);
