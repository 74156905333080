import { productAdapter, State } from './state';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { Product } from 'src/app/models/product';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectProductState: MemoizedSelector<object, State> = createFeatureSelector<State>('product');

export const selectAllProducts: (state: object) => Product[] = productAdapter.getSelectors(selectProductState).selectAll;

export const selectProducts = () => createSelector(
  selectAllProducts,
  (allProducts: Product[]) => {
    if (allProducts) {
      return allProducts;
    } else {
      return null;
    }
  }
);

export const selectProductBySlug = (slug: string) =>
  createSelector(
    selectAllProducts,
    (allProducts: Product[]) => {
      if (allProducts) {
        return allProducts.find(p => p.slug === slug);
      } else {
        return null;
      }
    }
  );

export const selectProductsByProductCategoryId = (product_category_id: number) =>
  createSelector(
    selectAllProducts,
    (allProducts: Product[]) => {
      if (allProducts) {
        return allProducts.filter(p => p.product_category_id == product_category_id);
      } else {
        return null;
      }
    }
  );

export const selectRelatedProducts = (product_category_id: number, id: number) => createSelector(
  selectAllProducts,
  (allProducts: Product[]) => {
    if (allProducts) {
      return allProducts.filter(p => p.product_category_id == product_category_id && p.id != id);
    } else {
      return null;
    }
  }
);

export const selectProductError: MemoizedSelector<object, any> = createSelector(
  selectProductState,
  getError
);

export const selectProductIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectProductState,
  getIsLoading
);
