import { ProductCategory } from './../models/productCategory';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getAll(): Observable<ProductCategory[]> {
    return this.httpClient.get<ProductCategory[]>('productcategories');
  }
}
