import { SliderService } from '../../services/slider.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as sliderActions from './actions';

@Injectable()
export class SliderStoreEffects {
  constructor(
    private sliderService: SliderService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<sliderActions.LoadSliderRequestAction>(
      sliderActions.ActionTypes.LOAD_SLIDER_REQUEST
    ),

    mergeMap(() => this.sliderService.getAll().pipe(
        map(items => new sliderActions.LoadSliderSuccessAction({ items })),
        catchError((error) => observableOf(new sliderActions.LoadSliderFailureAction({ error })))
      ))
    )
  );

}
