import { Slider } from '../../models/slider';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export const sliderAdapter: EntityAdapter<Slider> = createEntityAdapter<Slider>({
  selectId: model => model.id,
  sortComparer: (a: Slider, b: Slider): number => b.id.toString().localeCompare(a.id.toString())
});

export interface State extends EntityState<Slider> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = sliderAdapter.getInitialState({
  isLoading: false,
  error: null
});
