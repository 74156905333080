import { SeoService } from './../services/seo.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  constructor(
    private seoService: SeoService
  ) { }

  ngOnInit(): void {
    this.updateSeo();
  }

  updateSeo() {
    this.seoService.updateTitle('Digicontrol - Bosch Bina Otomasyonu - Anasayfa');
    this.seoService.updateMetaName('description', 'Digicontrol - Bosch Bina Otomasyonu - Anasayfa');
    this.seoService.updateMetaName('keywords', 'Digicontrol - Bosch Bina Otomasyonu, Bina Yönetim Platformu, Enerji Yönetim Platformu, Otomasyon İstasyonları, Genişleme Modülleri, Arabirim Modülleri, Dokunmatik Kullanıcı Panelleri, Blog, İletişim');
  }
}
