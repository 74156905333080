<section class="skill-area" id="productivity" *ngIf="!(isLoading$ | async)">
  <div class="container-fluid p-0">
    <div class="row m-0">
      <div class="col-lg-6 col-md-12 p-0">
        <!-- <div [innerHTML]="(page$ | async)?.description"></div> -->
        <div class="skill-content ptb-100">
          <div class="section-title">
            <h2>Verimlilik</h2>
          </div>
          <div class="skills">
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">ECONOMIZER ile %70’e varan tasarruf</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="70">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="70"></div>
                </div>
              </div>
            </div>
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">Akıllı oda havalandırmasıyla %60’a varan tasarruf</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="60">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="60"></div>
                </div>
              </div>
            </div>
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">Varlık kontrolü ile %20 ye varan tasarruf.</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="20">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="20"></div>
                </div>
              </div>
            </div>
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">Akıllı iklimlendirme kontrolü ile %30 a varan tasarruf</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="30">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="30"></div>
                </div>
              </div>
            </div>
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">Akıllı aydınlatma kontrolü ile %40 a varan tasarruf</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="40">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="40"></div>
                </div>
              </div>
            </div>
            <div class="skill-item">
              <div class="skill-header">
                <h3 class="skill-title" style="font-size: 18px;">Akıllı jaluzi kontrolü ile %30 a varan tasarruf</h3>
                <div class="skill-percentage">
                  <div class="count-box"><span class="count-text" data-speed="2000" data-stop="30">0</span>%</div>
                </div>
              </div>
              <div class="skill-bar">
                <div class="bar-inner">
                  <div class="bar progress-line" data-width="30"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 p-0">
        <div class="skill-image" [ngStyle]="(page$ | async)?.image !== null && {
          'width' : '100%',
          'height' : '100%',
          'background-image' : image_url,
          'background-position' : 'center',
          'background-repeat' : 'no-repeat',
          'background-size' : 'cover'
        }">
          <!-- <img [src]="(page$ | async)?.image.image_url" [alt]="(page$ | async)?.title"> -->
        </div>
      </div>
    </div>
  </div>
</section>
