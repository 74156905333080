import { Slider } from '../../models/slider';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_SLIDER_REQUEST = '[Slider] Load Request',
  LOAD_SLIDER_FAILURE = '[Slider] Load Failure',
  LOAD_SLIDER_SUCCESS = '[Slider] Load Success'
}

export class LoadSliderRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SLIDER_REQUEST;
}

export class LoadSliderFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SLIDER_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadSliderSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SLIDER_SUCCESS;

  constructor(public payload: { items: Slider[] }) {}
}

export type Actions = LoadSliderRequestAction | LoadSliderFailureAction | LoadSliderSuccessAction;
