<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" routerLink="/">
      <img src="assets/logo/digicontrol_white.png" alt="Digicontrol - Bosch Bina Otomasyonu">
      <img src="assets/logo/digicontrol.png" alt="Digicontrol - Bosch Bina Otomasyonu">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">

        <li class="nav-item" *ngFor="let item of navbar_links">
          <a pageScroll [routerLink]="item.routerLink" [href]="item.fragment" class="nav-link" [title]="item.title"
            [pageScrollOffset]="95">{{ item.title }}</a>
        </li>

        <!-- <li class="nav-item"><a class="nav-link" href="#home">Home</a></li>
              <li class="nav-item"><a class="nav-link" href="#about">About</a></li>
              <li class="nav-item"><a class="nav-link" href="#services">Services</a></li>
              <li class="nav-item"><a class="nav-link" href="#portfolio">Portfolio</a></li>
              <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
              <li class="nav-item"><a class="nav-link" href="#pricing">Pricing</a></li>
              <li class="nav-item"><a class="nav-link" href="#testimonial">Testimonial</a></li>
              <li class="nav-item"><a class="nav-link" href="#blog">Blog</a></li>
              <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li> -->
      </ul>
    </div>
  </div>
</nav>
