import { Product } from './../../models/product';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export const productAdapter: EntityAdapter<Product> = createEntityAdapter<Product>({
  selectId: model => model.id
});

export interface State extends EntityState<Product> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = productAdapter.getInitialState({
  isLoading: false,
  error: null
});
