import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FooterSetting } from '../models/footerSetting';
import { ContactInfoSetting } from '../models/contactInfoSetting';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    private httpClient: HttpClient
  ) { }

  getFooterSetting(): Observable<FooterSetting> {
    return this.httpClient.get<FooterSetting>('footersetting');
  }

  getContactInfoSetting(): Observable<ContactInfoSetting> {
    return this.httpClient.get<ContactInfoSetting>('contactinfosetting');
  }
}
