<ul *ngIf="pager.pages && pager.pages.length" class="pagination justify-content-center">

  <!-- <li [ngClass]="{disabled:pager.currentPage === 1}" class="page-item">
    <a (click)="setPage(1)" class="page-link">İlk</a>
  </li> -->

  <li [ngClass]="{ disabled : pager.currentPage === 1 }" class="page-item">
    <a (click)="setPage(pager.currentPage - 1)" class="page-link">
      <i class="icofont-double-left"></i>
    </a>
  </li>

  <li *ngFor="let page of pager.pages" class="page-item">
    <a (click)="setPage(page)" class="page-link" [ngClass]="{ 'active' : pager.currentPage === page }">
      {{ page }}
    </a>
  </li>

  <li [ngClass]="{ disabled : pager.currentPage === pager.totalPages }" class="page-item">
    <a (click)="setPage(pager.currentPage + 1)" class="page-link">
      <i class="icofont-double-right"></i>
    </a>
  </li>

  <!-- <li [ngClass]="{disabled:pager.currentPage === pager.totalPages}" class="page-item">
    <a (click)="setPage(pager.totalPages)" class="page-link">Son</a>
  </li> -->

</ul>
