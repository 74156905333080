import { Actions, ActionTypes } from './actions';
import { pageAdapter, initialState, State } from './state';

export function pageReducer(state = initialState, action: Actions): State {
  switch(action.type) {

    case ActionTypes.LOAD_PAGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ActionTypes.LOAD_PAGE_SUCCESS: {
      return pageAdapter.addMany(action.payload.items, {
        ...state,
        isLoading: false,
        error: null
      });
    }

    case ActionTypes.LOAD_PAGE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    default: {
      return state;
    }
  }
}
