import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { postReducer } from './reducer';
import { PostStoreEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('post', postReducer),
    EffectsModule.forFeature([
      PostStoreEffects
    ])
  ]
})
export class PostStoreModule { }
