import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { SliderStoreModule } from './slider-store';
import { ProductCategoryStoreModule } from './product-category-store';
import { PageStoreModule } from './page-store';
import { PostStoreModule } from './post-store';
import { ProductStoreModule } from './product-store';
import { SectionStoreModule } from './section-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SliderStoreModule,
    ProductCategoryStoreModule,
    PageStoreModule,
    PostStoreModule,
    ProductStoreModule,
    SectionStoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([])
  ]
})
export class RootStoreModule { }
