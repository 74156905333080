import { postAdapter, State } from './state';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { Post } from 'src/app/models/post';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectPostState: MemoizedSelector<object, State> = createFeatureSelector<State>('post');

export const selectAllPosts: (state: object) => Post[] = postAdapter.getSelectors(selectPostState).selectAll;

export const selectPostByFeatured = (featured: number) =>
  createSelector(
    selectAllPosts,
    (allPosts: Post[]) => {
      if (allPosts) {
        return allPosts.filter(p => p.featured == featured);
      } else {
        return null;
      }
    }
  );

export const selectPostBySlug = (slug: string) =>
  createSelector(
    selectAllPosts,
    (allPosts: Post[]) => {
      if (allPosts) {
        return allPosts.find(p => p.slug === slug);
      } else {
        return null;
      }
    }
  );

export const selectOtherPosts = (slug: string) => createSelector(
  selectAllPosts,
    (allPosts: Post[]) => {
      if (allPosts) {
        return allPosts.filter(p => p.slug !== slug);
      } else {
        return null;
      }
    }
);

export const selectPostError: MemoizedSelector<object, any> = createSelector(
  selectPostState,
  getError
);

export const selectPostIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectPostState,
  getIsLoading
);
