import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './components/layouts/slider/slider.component';
import { ContactComponent } from './components/layouts/contact/contact.component';
import { SolutionsComponent } from './components/layouts/solutions/solutions.component';
import { AboutComponent } from './components/layouts/about/about.component';
import { ProductivityComponent } from './components/layouts/productivity/productivity.component';
import { CtaOneComponent } from './components/layouts/cta-one/cta-one.component';
import { BlogComponent } from './components/layouts/blog/blog.component';
import { OwlModule } from 'ngx-owl-carousel';
import { CtaContactComponent } from './components/layouts/cta-contact/cta-contact.component';

import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { NgxPageScrollModule } from 'ngx-page-scroll';

@NgModule({
  declarations: [
    SliderComponent,
    ContactComponent,
    SolutionsComponent,
    AboutComponent,
    ProductivityComponent,
    CtaOneComponent,
    BlogComponent,
    CtaContactComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TabsModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OwlModule,
    NgxPageScrollCoreModule.forRoot(),
    NgxPageScrollModule,
  ],
  exports: [
    SliderComponent,
    ContactComponent,
    SolutionsComponent,
    AboutComponent,
    ProductivityComponent,
    CtaOneComponent,
    BlogComponent,
    CtaContactComponent,
  ]
})
export class SharedModule { }
