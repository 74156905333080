<section class="cta-area" id="cta1">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-8 col-md-8">
        <div class="cta-content">
          <h3>Dijital dönüşümünüzü maksimum verimlilikle gerçekleştirmek için şimdi <b>teklif</b> alın</h3>
          <!-- <span>Create lovely & smart websites faster! Get Avurn now!</span> -->
        </div>
      </div>
      <div class="col-lg-4 col-md-4 text-right">
        <a pageScroll routerLink="/" href="#contact" class="btn btn-primary">Teklif Al</a>
      </div>
    </div>
  </div>
</section>
