<owl-carousel *ngIf="!(isLoading$ | async)" [carouselClasses]="['home-slides', 'owl-carousel', 'owl-theme']"
  [options]="owlOptions">

  <div class="main-banner text-animation-home" *ngFor="let slider of sliders$ | async; let i = index"
    [ngStyle]="{ 'background-image' : 'url(' + slider.image.image_url + ')' }">

    <div class="d-table">
      <div class="d-table-cell">
        <div class="container">
          <div class="main-banner-content text-center">
            <span [innerText]="slider.short_title"></span>
            <h1 [innerHTML]="slider.long_title"></h1>
            <p *ngIf="slider.description !== null" [innerText]="slider.description"></p>
            <div class="btn-box">

              <ng-container *ngIf="slider.url_href !== null">
                <ng-container *ngIf="slider.url_type === 'router_link'; else otherurl">
                  <a [routerLink]="slider.url_href" pageScroll class="btn btn-primary" [title]="slider.url_title"
                    [href]="slider.url_anchor" [pageScrollOffset]="95">
                    {{ slider.url_title }}
                  </a>
                </ng-container>

                <ng-template #otherurl>
                  <a [href]="slider.url_href" class="btn btn-primary" [title]="slider.url_title"
                    [target]="slider.url_target">{{ slider.url_title }}</a>
                </ng-template>
              </ng-container>

              <span *ngIf="slider.url_href !== null">veya</span>

              <ng-container *ngIf="slider.video_url_href !== null && slider.video_url_title !== null">
                <a [href]="slider.video_url_href" class="video-btn popup-youtube">
                  <i class="icofont-ui-play"></i> {{ slider.video_url_title }}
                </a>
              </ng-container>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</owl-carousel>
