import { Injectable } from '@angular/core';
declare let $: any;

@Injectable({
  providedIn: 'root'
})
export class BusyService {
  busyRequestCount = 0;

  constructor() { }

  busy() {
    this.busyRequestCount++;
    $('.preloader').fadeIn('slow');
  }

  idle() {
    this.busyRequestCount--;
    if (this.busyRequestCount <= 0) {
      this.busyRequestCount = 0;
      $.getScript('../assets/js/main.js');
      $('.preloader').fadeOut('slow');
      window.scrollTo(0, 0);
    }
  }
}
