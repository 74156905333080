import { Actions, ActionTypes } from './actions';
import { postAdapter, initialState, State } from './state';

export function postReducer(state = initialState, action: Actions): State {
  switch(action.type) {

    case ActionTypes.LOAD_POST_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ActionTypes.LOAD_POST_SUCCESS: {
      return postAdapter.addMany(action.payload.items, {
        ...state,
        isLoading: false,
        error: null
      });
    }

    case ActionTypes.LOAD_POST_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    default: {
      return state;
    }
  }
}
