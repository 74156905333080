import { Slider } from '../../models/slider';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';


import { sliderAdapter, State } from './state';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectSliderState: MemoizedSelector<object, State> = createFeatureSelector<State>('slider');


export const selectAllSliders: (state: object) => Slider[] = sliderAdapter.getSelectors(selectSliderState).selectAll;


export const selectSliderError: MemoizedSelector<object, any> = createSelector(
  selectSliderState,
  getError
);


export const selectSliderIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectSliderState,
  getIsLoading
);
