import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navbar_links: any[] = [
    { title: 'Anasayfa', 'routerLink': '/', 'fragment': '#home' },
    { title: 'DIGICONTROL Hakkında', 'routerLink': '/', 'fragment': '#about' },
    { title: 'Teklif Al', 'routerLink': '/', 'fragment': '#cta1' },
    { title: 'Sistemler', 'routerLink': '/', 'fragment': '#solutions' },
    { title: 'Ürünler', 'routerLink': '/urunler', 'fragment': '#solutions' },
    { title: 'Verimlilik', 'routerLink': '/', 'fragment': '#productivity' },
    //{ title: 'Blog', 'routerLink': '/', 'fragment': '#blog' },
    { title: 'İletişim', 'routerLink': '/', 'fragment': '#contact' }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
