import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'SearchPipe'
})
export class SearchPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {

    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLocaleLowerCase();

    return items.filter(item => {
      let title = JSON.stringify(item['title']).toLocaleLowerCase();
      let description = JSON.stringify(item['description']).toLocaleLowerCase();

      return title.includes(searchText) || description.includes(searchText);
    });
  }
}
