import { Post } from 'src/app/models/post';
import { Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootStoreState, PostStoreActions, PostStoreSelectors } from 'src/app/root-store';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {

  posts$: Observable<Post[]> = this.store$.select(PostStoreSelectors.selectPostByFeatured(1));
  error$: Observable<string> = this.store$.select(PostStoreSelectors.selectPostError);
  isLoading$: Observable<boolean> = this.store$.select(PostStoreSelectors.selectPostIsLoading);

  owlOptions = {
    loop: true,
    nav: false,
    dots: true,
    navigation: false,
    autoplayHoverPause: true,
    margin: 30,
    responsiveClass:true,
    responsive: {
      0: {
        items:1
      },
      480: {
        items:1
      },
      768 : {
        items:3
      },
    }
  };

  carouselClasses = ['blog-slides', 'owl-carousel', 'owl-theme'];

  constructor(
    private store$: Store<RootStoreState.State>
  ) { }

  ngOnInit(): void {
    this.getAll();
  }

  getAll() {
    this.store$.dispatch(new PostStoreActions.LoadPostRequestAction());
  }
}
