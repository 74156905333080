import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private title: Title,
    private meta: Meta
  ) { }

  updateTitle(title: string) {
    this.title.setTitle(title);
    this.meta.addTag({ name: 'robots', content: 'index,follow' });
  }

  updateMetaName(name: string, content: string) {
    this.meta.updateTag({ name: name, content: content });
  }

  updateMetaProperty(property: string, content: string) {
    this.meta.updateTag({ property: property, content: content });
  }
}
