import { Page } from './../../models/page';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export const pageAdapter: EntityAdapter<Page> = createEntityAdapter<Page>({
  selectId: model => model.id
});

export interface State extends EntityState<Page> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = pageAdapter.getInitialState({
  isLoading: false,
  error: null
});
