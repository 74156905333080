import { PageService } from './../../services/page.service';
import { Injectable } from '@angular/core';
import {  of as observableOf } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as PageActions from './actions';

@Injectable()
export class PageStoreEffects {
  constructor(
    private pageService: PageService,
    private actions$: Actions
  ) { }

  loadRequestEffect$ = createEffect(() => this.actions$.pipe(
    ofType<PageActions.LoadPageRequestAction>(
      PageActions.ActionTypes.LOAD_PAGE_REQUEST
    ),

    mergeMap(() => this.pageService.getAll().pipe(
        map(items => new PageActions.LoadPageSuccessAction({ items })),
        catchError((error) => observableOf(new PageActions.LoadPageFailureAction({ error })))
      ))
    )
  );
}
