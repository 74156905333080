<section class="why-we-different ptb-100 bg-image" id="solutions">
  <div class="container">
    <div class="section-title">
      <span>ÇALIŞMA ALANLARI İÇİN ARTTIRILMIŞ VERİMLİLİK</span>
      <h2>Bosch Otomasyon Sistemleri İş Ortağı: <b>ATEKSİS</b></h2>
      <p>Akıllı binalar için daha fazla konfor, günvelilik, verimlilik ve sürdürülebilirlik için ATEKSİS'ten çözümler...</p>
    </div>

    <div class="tab" *ngIf="!(isLoading$ | async)">
      <tabset [justified]="true">
          <tab *ngFor="let product_category of productCategories$ | async as productCategories; index as i" [heading]="product_category.title">
            <div class="tabs_item">
              <div class="row align-items-center">
                <ng-container *ngIf="i % 2 === 0; else template2">
                  <div class="col-lg-6 col-md-12 why-we-different-content">
                    <div class="tabs_item_content">
                      <div [innerHtml]="product_category.description"></div>
                      <a [routerLink]="'/kategoriler/' + product_category.slug" class="btn btn-primary" [title]="product_category.title">Detaylar</a>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 why-we-different-img">
                    <div class="tabs_item_img">
                      <img [src]="product_category.image?.image_url" [alt]="product_category.title" />
                    </div>
                  </div>
                </ng-container>
                <ng-template #template2>
                  <div class="col-lg-6 col-md-12 why-we-different-img">
                    <div class="tabs_item_img">
                      <img [src]="product_category.image?.image_url" [alt]="product_category.title" />
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 why-we-different-content">
                    <div class="tabs_item_content">
                      <div [innerHtml]="product_category.description"></div>
                      <a [routerLink]="'/kategoriler/' + product_category.slug" class="btn btn-primary" [title]="product_category.title">Detaylar</a>
                    </div>
                  </div>
                </ng-template>
              </div>
            </div>
          </tab>
      </tabset>
    </div>
  </div>
</section>

<!-- <section *ngIf="!(isLoading$ | async)" class="why-we-different ptb-100 bg-image" id="solutions">
  <div class="container">
    <div class="section-title">
      <span>ÇALIŞMA ALANLARI İÇİN ARTTIRILMIŞ VERİMLİLİK</span>
      <h2>Bosch Otomasyon Sistemleri Pilot Entegratörü: <b>ATEKSİS</b></h2>
      <p>Akıllı binalar için daha fazla konfor, günvelilik, verimlilik ve sürdürülebilirlik için ATEKSİS'ten çözümler...
      </p>
    </div>
    <div class="tab">

      <ul class="tabs" role="tablist" style="border-bottom: 0;">
        <li *ngFor="let product_category of productCategories$ | async as productCategories; index as i"
          [ngClass]="{'current' : currentTab === i}">

          <a class="nav-link" [ngClass]="{'active' : currentTab === i}" [href]="'#item'+i" role="tab" data-toggle="tab"
            (click)="currentTab = i" [title]="product_category.title">
            {{product_category.title}}
          </a>
        </li>
      </ul>

      <div class="tab-content tab_content">

        <div class="tabs_item" [id]="'item' + i"
          *ngFor="let product_category of productCategories$ | async as productCategories; index as i"
          [ngClass]="{'active show' : currentTab === i}">
          <div class="row align-items-center">
            <ng-container *ngIf="i % 2 === 0; else template2">
              <div class="col-lg-6 col-md-12 why-we-different-content">
                <div class="tabs_item_content">
                  <div [innerHtml]="product_category.description"></div>
                  <a [routerLink]="'/kategoriler/' + product_category.slug" class="btn btn-primary"
                    [title]="product_category.title">Detaylar</a>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 why-we-different-img">
                <div class="tabs_item_img">
                  <img [src]="product_category.image?.image_url" [alt]="product_category.title" />
                </div>
              </div>
            </ng-container>
            <ng-template #template2>
              <div class="col-lg-6 col-md-12 why-we-different-img">
                <div class="tabs_item_img">
                  <img [src]="product_category.image?.image_url" [alt]="product_category.title" />
                </div>
              </div>
              <div class="col-lg-6 col-md-12 why-we-different-content">
                <div class="tabs_item_content">
                  <div [innerHtml]="product_category.description"></div>
                  <a [routerLink]="'/kategoriler/' + product_category.slug" class="btn btn-primary"
                    [title]="product_category.title">Detaylar</a>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->
