import { SearchPipe } from './../pipes/search.pipe';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from './components/layouts/navbar/navbar.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { PaginationComponent } from './components/layouts/pagination/pagination.component';

import { NgxPageScrollModule } from 'ngx-page-scroll';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';

@NgModule({
  declarations: [
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    PaginationComponent,
    SearchPipe,
  ],
  imports: [
    CommonModule,
    RouterModule,
    // NgxPageScrollCoreModule.forRoot({
    //   duration: 1000,
    // }),
    NgxPageScrollCoreModule.forRoot(),
    NgxPageScrollModule,
  ],
  exports: [
    NavbarComponent,
    FooterComponent,
    PreloaderComponent,
    PaginationComponent,
    SearchPipe,
  ]
})
export class CoreModule { }
