import { FooterSetting } from './../../../../models/footerSetting';
import { SettingService } from './../../../../services/setting.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  footerSetting: FooterSetting;
  loading: boolean = false;

  constructor(
    private settingService: SettingService
  ) { }

  ngOnInit(): void {
    this.getFooterSetting();
  }

  getFooterSetting() {
    this.loading = true;
    this.settingService.getFooterSetting().subscribe((response: FooterSetting) => {
      this.footerSetting = response;
      this.loading = false;
    });
  }
}
