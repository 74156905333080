import { pageAdapter, State } from './state';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector
} from '@ngrx/store';
import { Page } from 'src/app/models/page';

export const getError = (state: State): any => state.error;
export const getIsLoading = (state: State): boolean => state.isLoading;

export const selectPageState: MemoizedSelector<object, State> = createFeatureSelector<State>('page');

export const selectAllPages: (state: object) => Page[] = pageAdapter.getSelectors(selectPageState).selectAll;

export const selectPageByLayout = (layout: string) =>
  createSelector(
    selectAllPages,
    (allPages: Page[]) => {
      if (allPages) {
        return allPages.find(p => p.layout === layout);
      } else {
        return null;
      }
    }
  );

export const selectPageError: MemoizedSelector<object, any> = createSelector(
  selectPageState,
  getError
);

export const selectPageIsLoading: MemoizedSelector<object, boolean> = createSelector(
  selectPageState,
  getIsLoading
);
