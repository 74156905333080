import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { productCategoryReducer } from './reducer';
import { ProductCategoryStoreEffects } from './effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('productCategory', productCategoryReducer),
    EffectsModule.forFeature([
      ProductCategoryStoreEffects
    ])
  ]
})
export class ProductCategoryStoreModule { }
