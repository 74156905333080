import { Post } from './../../models/post';
import { EntityAdapter, createEntityAdapter, EntityState } from '@ngrx/entity';

export const postAdapter: EntityAdapter<Post> = createEntityAdapter<Post>({
  selectId: model => model.id
});

export interface State extends EntityState<Post> {
  isLoading?: boolean;
  error?: any;
}

export const initialState: State = postAdapter.getInitialState({
  isLoading: false,
  error: null
});
