import { Post } from './../../models/post';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_POST_REQUEST = '[Post] Load Request',
  LOAD_POST_FAILURE = '[Post] Load Failure',
  LOAD_POST_SUCCESS = '[Post] Load Success'
}

export class LoadPostRequestAction implements Action {
  readonly type = ActionTypes.LOAD_POST_REQUEST;
}

export class LoadPostFailureAction implements Action {
  readonly type = ActionTypes.LOAD_POST_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadPostSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_POST_SUCCESS;

  constructor(public payload: { items: Post[] }) {}
}

export type Actions = LoadPostRequestAction | LoadPostFailureAction | LoadPostSuccessAction;
