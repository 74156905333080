import { Actions, ActionTypes } from './actions';
import { productAdapter, initialState, State } from './state';

export function productReducer(state = initialState, action: Actions): State {
  switch(action.type) {

    case ActionTypes.LOAD_PRODUCT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case ActionTypes.LOAD_PRODUCT_SUCCESS: {
      return productAdapter.addMany(action.payload.items, {
        ...state,
        isLoading: false,
        error: null
      });
    }

    case ActionTypes.LOAD_PRODUCT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      };
    }

    default: {
      return state;
    }
  }
}
