import { Observable } from 'rxjs';
import { Page } from './../../../../models/page';
import { Component, OnInit } from '@angular/core';

import { PageStoreActions, PageStoreSelectors, RootStoreState } from 'src/app/root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  layout = 'about';
  page$: Observable<Page> = this.store$.select(PageStoreSelectors.selectPageByLayout(this.layout));
  error$: Observable<string> = this.store$.select(PageStoreSelectors.selectPageError);
  isLoading$: Observable<boolean> = this.store$.select(PageStoreSelectors.selectPageIsLoading);

  constructor(
    private store$: Store<RootStoreState.State>
  ) { }

  ngOnInit(): void {
    this.getPage();
  }

  getPage() {
    this.store$.dispatch(new PageStoreActions.LoadPageRequestAction());
  }
}
