import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '', component: HomeComponent,
    data: {
      breadcrumb: {
        label: 'Anasayfa',
        info: 'home',
        alias: 'home'
      },
    }
  },
  {
    path: 'kategoriler/:slug',
    loadChildren: () => import('./product-category/product-category.module').then(mode => mode.ProductCategoryModule),
    data: {
      breadcrumb: {
        label: '',
        info: 'productCategoryDetails',
        alias: 'productCategoryDetails'
      },
    }
  },
  {
    path: 'urunler',
    loadChildren: () => import('./product/product.module').then(mode => mode.ProductModule),
    data: {
      breadcrumb: {
        label: 'Ürünler',
        info: 'products',
        alias: 'products'
      },
    }
  },
  {
    path: 'blog',
    loadChildren: () => import('./post/post.module').then(mode => mode.PostModule),
    data: {
      breadcrumb: {
        label: 'Blog',
        info: 'post',
        alias: 'post'
      },
    }
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      // scrollPositionRestoration: 'enabled'
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
