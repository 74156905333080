import { SectionStoreModule } from './root-store/section-store/section-store.module';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { ProductStoreModule } from './root-store/product-store/product-store.module';
import { PostStoreModule } from './root-store/post-store/post-store.module';
import { PageStoreModule } from './root-store/page-store/page-store.module';
import { ProductCategoryStoreModule } from './root-store/product-category-store/product-category-store.module';
import { SliderStoreModule } from './root-store/slider-store/slider-store.module';

import { ApiInterceptor } from './interceptors/api.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeModule } from './home/home.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { BreadcrumbModule } from 'xng-breadcrumb';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    CoreModule,
    HomeModule,
    BrowserAnimationsModule,

    SliderStoreModule,
    ProductCategoryStoreModule,
    PageStoreModule,
    PostStoreModule,
    ProductStoreModule,
    SectionStoreModule,

    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    BreadcrumbModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
