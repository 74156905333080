import { Section } from '../../models/section';
import { Action } from '@ngrx/store';

export enum ActionTypes {
  LOAD_SECTION_REQUEST = '[Section] Load Request',
  LOAD_SECTION_FAILURE = '[Section] Load Failure',
  LOAD_SECTION_SUCCESS = '[Section] Load Success'
}

export class LoadSectionRequestAction implements Action {
  readonly type = ActionTypes.LOAD_SECTION_REQUEST;
}

export class LoadSectionFailureAction implements Action {
  readonly type = ActionTypes.LOAD_SECTION_FAILURE;

  constructor(public payload: { error: string }) {}
}

export class LoadSectionSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SECTION_SUCCESS;

  constructor(public payload: { items: Section[] }) {}
}

export type Actions = LoadSectionRequestAction | LoadSectionFailureAction | LoadSectionSuccessAction;
